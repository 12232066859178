import { Popover, Transition } from '@headlessui/react'
import {
  buttonVariants,
  HeaderNavigation,
} from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from './image'
import { Icon } from '~/components/icon.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import {
  type ComponentSharedButton,
  type ComponentSharedLink,
  type NavbarSettingsFragment,
} from '~/model/api.ts'
import { LanguageSelector } from '~/routes/resources.language-selector.tsx'

import { useIsHome, useScrollDepth } from '~/utils/misc.ts'

type Props = {
  navbarSettings?: NavbarSettingsFragment | null
}

export function Navbar({ navbarSettings }: Props) {
  const isHome = useIsHome()
  const scrollYPosition = useScrollDepth()

  if (navbarSettings) {
    return (
      <div
        className={cn(
          'z-[1000] bg-background-canvas transition-colors duration-300',
          navbarSettings.fixed === true
            ? cn(
                'fixed left-0 right-0 top-0',
                navbarSettings.transparent === true
                  ? `${scrollYPosition === 0 ? 'md:bg-opacity-0' : 'bg-opacity-100'}`
                  : '',
              )
            : navbarSettings.transparent === true
              ? 'absolute left-0 right-0 top-0 md:bg-opacity-0'
              : '',
        )}
      >
        <div className="container flex h-20 justify-between">
          <div className="flex items-center">
            {navbarSettings.logoLink ? (
              <NormalizedLink
                to={navbarSettings.logoLink?.url ?? '/'}
                aria-label={navbarSettings.logoLink?.title}
              >
                <Icon className="h-[16px] w-[112px]" name="rouvy-logo" />
              </NormalizedLink>
            ) : (
              <NormalizedLink to="/" aria-label="Rouvy">
                <Icon className="h-[16px] w-[112px]" name="rouvy-logo" />
              </NormalizedLink>
            )}

            <PartnersLogo
              logo={navbarSettings.secondaryLogo}
              link={navbarSettings.secondaryLogoLink}
            />

            {navbarSettings.navLinks && navbarSettings.navLinks.length > 0 && (
              <div className="ml-10 hidden text-body-14 lg:block">
                <HeaderNavigation
                  items={navbarSettings.navLinks?.map((link) => ({
                    node: (
                      <NormalizedLink to={link?.url ?? ''}>
                        {link?.title}
                      </NormalizedLink>
                    ),
                    external: false,
                    active: false,
                  }))}
                />
              </div>
            )}
          </div>
          <div className="flex items-center">
            <DesktopNav
              buttons={navbarSettings?.buttons as ComponentSharedButton[]}
            />
            <MobileNav
              buttons={navbarSettings?.buttons as ComponentSharedButton[]}
              links={navbarSettings?.navLinks as ComponentSharedLink[]}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'z-[1000]',
        !isHome
          ? 'bg-background-canvas'
          : 'absolute left-0 right-0 top-0 bg-transparent',
      )}
    >
      <div className="container flex h-[80px] items-center justify-between overflow-hidden">
        <NormalizedLink to="/" aria-label="Rouvy">
          <Icon className="h-[16px] w-[112px]" name="rouvy-logo" />
        </NormalizedLink>
        <div>
          <DesktopNav />
          <MobileNav />
        </div>
      </div>
    </div>
  )
}

function DesktopNav({ buttons }: { buttons?: ComponentSharedButton[] | null }) {
  const { t } = useTranslation()

  return (
    <nav
      className={cn(
        'hidden items-center md:ml-10 md:w-full md:flex-row lg:flex',
      )}
    >
      <div className="flex items-center gap-8">
        <div className="relative">
          <LanguageSelector />
        </div>

        <div className="flex items-center space-x-2">
          {buttons ? (
            buttons.map((button, index) => (
              <NormalizedLink
                key={index}
                to={button.link}
                className={buttonVariants({
                  variant: button.buttonStyle ?? 'primary',
                  size: 'small',
                })}
              >
                {button.text}
              </NormalizedLink>
            ))
          ) : (
            <>
              <NormalizedLink
                to={ENV.ACCOUNT_PORTAL_URL}
                className={buttonVariants({
                  variant: 'primary',
                  size: 'small',
                })}
              >
                {t('join_now')}
              </NormalizedLink>
              <NormalizedLink
                to={ENV.RIDERS_PORTAL_URL}
                className={buttonVariants({
                  variant: 'secondary',
                  size: 'small',
                })}
              >
                {t('login')}
              </NormalizedLink>
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

function MobileNav({
  buttons,
  links,
}: {
  buttons?: ComponentSharedButton[] | null
  links?: ComponentSharedLink[] | null
}) {
  const { t } = useTranslation()

  return (
    <div className="flex gap-6 lg:hidden">
      <LanguageSelector simple />

      <Popover className="md:relative">
        {() => (
          <div>
            <Popover.Button
              className="my-auto flex items-center gap-1 rounded-full border border-button-alternative-border-default bg-transparent px-4 py-[10px] uppercase text-text-default focus:outline-none"
              aria-label="Main navigation"
            >
              <span className="text-body-12 font-bold">{t('menu')}</span>
              <Icon className="h-6 w-6 text-text-default" name="arrow-down" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute left-0 right-0 top-20 z-[1001] overflow-hidden text-body-14 font-medium leading-4 md:left-auto md:right-0 md:top-16 md:min-w-[268px] md:origin-top-right">
                <div className="divide-y divide-border-secondary border-t border-border-secondary bg-background-subtle lg:border-none">
                  <div className="flex flex-col py-1">
                    {links && links?.length > 0 && (
                      <>
                        {links.map((item, key) => (
                          <Popover.Button
                            as={NormalizedLink}
                            to={item.url}
                            className="flex w-full items-center px-4 py-3 text-text-default hover:bg-background-inset"
                            key={key}
                          >
                            {item.title}
                          </Popover.Button>
                        ))}
                      </>
                    )}
                  </div>
                  {buttons && buttons?.length > 0 && (
                    <div className="flex flex-col gap-2 px-4 py-6">
                      {buttons.map((item, key) => (
                        <Popover.Button
                          as={NormalizedLink}
                          to={item.link}
                          className={cn(
                            'flex w-full justify-center',
                            buttonVariants({
                              variant: item.buttonStyle ?? 'primary',
                              size: 'small',
                            }),
                          )}
                          key={key}
                        >
                          {item.text}
                        </Popover.Button>
                      ))}
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  )
}

function PartnersLogo({
  logo,
  link,
}: {
  logo?: NavbarSettingsFragment['secondaryLogo']
  link?: NavbarSettingsFragment['secondaryLogoLink']
}) {
  return (
    logo?.data?.attributes?.url && (
      <div className="partners flex">
        <div className="mx-4 w-[1px] bg-global-white"></div>

        {link ? (
          <NormalizedLink to={link ?? '/'} target="_blank">
            <Image
              src={logo?.data?.attributes?.url}
              className="relative bottom-1"
            />
          </NormalizedLink>
        ) : (
          <Image
            src={logo?.data?.attributes?.url}
            className="relative bottom-1"
          />
        )}
      </div>
    )
  )
}
