import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
  type ErrorResponse,
} from 'react-router'
import { Error } from './ui/errors/error'
import { Gradient } from '~/components/gradients.tsx'
import { Layout } from '~/components/layout.tsx'
import { getErrorMessage } from '~/utils/misc.ts'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => React.ReactElement | null

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <Error description={`${error.status} ${error.data}`} />
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => (
    <Error description={getErrorMessage(error)} />
  ),
}: {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => React.ReactElement | null
}) {
  const error = useRouteError()
  const params = useParams()

  if (typeof document !== 'undefined') {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return (
    <Layout>
      <div className="relative h-full">
        <Gradient />
        <div className="container relative flex h-full flex-col items-center justify-center md:pt-[80px]">
          {isRouteErrorResponse(error)
            ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
                error,
                params,
              })
            : unexpectedErrorHandler(error)}
        </div>
      </div>
    </Layout>
  )
}
