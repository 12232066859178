import { type ContentLayersFragment } from '~/model/api.ts'

function dataLayerPush(data: {}) {
  // For dev env this is not initialized
  if (window.dataLayer) {
    window.dataLayer.push({ ...data, timestamp: new Date().getTime() })
  }
}

export function trackEvent(event: string, data?: object) {
  dataLayerPush({
    event,
    ...data,
  })
}

export function pageView(path: string, locale: string | undefined) {
  dataLayerPush({
    page: {
      pageURL: path,
      pageLang: locale,
    },
  })
}

export function trackAbTest(key: string, variant: string) {
  dataLayerPush({
    abTests: {
      key: key,
      variant: variant,
    },
  })
  trackEvent('abTests', {
    abTests: {
      key: key,
      variant: variant,
    },
  })
}

export function trackContentLayers(
  data: ContentLayersFragment,
  pathname: string,
) {
  trackEvent('contentLayers', {
    contentLayers: {
      pathname,
      ...data,
    },
  })
}
