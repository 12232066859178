import { buttonVariants } from '@rouvydev/web-components/basics'
import { ContentCtaBlock } from '@rouvydev/web-components/content'
import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '../../normalized-link'
import { type ErrorProps } from './error.types'

export function Error({
  title,
  description,
  image,
  link = '/',
  buttonText,
  className,
}: ErrorProps) {
  const { t } = useTranslation(['common'])

  return (
    <ContentCtaBlock
      title={title ?? t('common::whoops')}
      description={description ?? ''}
      button={
        <NormalizedLink
          to={link}
          className={buttonVariants({ variant: 'alternative', size: 'big' })}
        >
          {buttonText ?? t('common::go_to_homepage')}
        </NormalizedLink>
      }
      className={className}
    >
      {image && (
        <ContentCtaBlock.Image src={image} className="py-0 md:py-0 lg:py-0" />
      )}
    </ContentCtaBlock>
  )
}
