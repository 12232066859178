import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'

const ignoredErrors = [/exponea/i, /Minified React error/i]

function initDataDog(
  applicationId: string,
  clientToken: string,
  releaseVersion: string | undefined,
) {
  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: 'datadoghq.eu',
    service: 'rouvy.com-remix',
    env: 'production',
    version: releaseVersion ?? 'default',
    sessionSampleRate: 20,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    beforeSend: (event) => {
      return !(
        event.type === 'error' &&
        ignoredErrors.some((re) => re.test(event.error.message))
      )
    },
  })

  return datadogRum
}

export function useDatadogRum(
  appId: string | undefined,
  clientToken: string | undefined,
  releaseVersion: string | undefined,
) {
  useEffect(() => {
    if (appId && clientToken) {
      initDataDog(appId, clientToken, releaseVersion)
    }
  }, [appId, clientToken, releaseVersion])
}
